.container {
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.localeFlag {
  width: 33px;
  height: 20px;
  position: relative;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &.active {
    border-bottom: 2px solid white;
    padding-bottom: 15px;
    cursor: default;
  }

  &:not(.active) {
    &:hover {
      transform: scale(1.1);
    }
  }
}
