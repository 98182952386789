@import '../../styles/variables.scss';

.word {
  span:first-child {
    font-family: 'Harry Beast Display', $japaneseFallbackFonts, serif;
  }
  span:last-child {
    font-family: $harrybeastregular;
  }
}
