@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.wwlogo {
  width: 122px;
  height: 45px;
  margin: 16px auto 25px;

  @include media-breakpoint-up(md) {
    width: 220px;
    height: 80px;
    margin: 32px auto 45px;
  }
}

.loginButton {
  position: absolute;
  right: 0;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.backBtn {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 30px;

  @include media-breakpoint-up(md) {
    top: 60px;
  }

  &:hover {
    opacity: 0.5;
  }
}

.back {
  background: url('/images/icons/back-24px-13px.svg') no-repeat;
  background-size: 100% auto;
  height: 24px;
  width: 13px;
  margin: 10px;
}