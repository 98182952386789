@import '../../styles/variables.scss';

.picture {
  display: block;
}

.contain {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    overflow: hidden;
    object-position: top;

    &.bottom {
      object-position: bottom;
    }
    &.center {
      object-position: 50% 50%;
    }
  }
}

.cover {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    overflow: hidden;
    object-position: top;

    &.bottom {
      object-position: bottom;
    }

    &.center {
      object-position: 50% 50%;
    }
  }
}

.static {
  position: static !important;
}

.gradient {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(15, 30, 52, 0), #10141b);
  }
}

.overlay {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba($bunker, 0.4);
  }
}

.fade {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(16, 20, 27, 0),
      rgba(16, 20, 27, 0.72) 32%,
      #10141b 60%,
      rgba(16, 20, 27, 0)
    );
  }
}

.hidden {
  display: none;
}

.loaderImg {
  filter: blur(20px);
}

.autoHeight {
  height: auto;
}
