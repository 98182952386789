@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.basicInput {
  :global {
    .label-txt,
    .input-txt {
      display: block;
      width: 100%;
    }
    .col-md-10 {
      margin: 0 auto;
      padding: 0 0 30px 0;
    }

    .input-container {
      margin-bottom: 32px;
    }

    .input-txt {
      box-sizing: border-box;
      font-weight: normal;
      font-size: $form-input-size;
      padding: $form-input-padding;
      margin: $form-input-margin;
      border: 1px solid $grey-legal-dark;
      border-radius: $form-input-radius;
      background: rgba($input-bg, 0.5);
      font-family: $sofiapro;
      line-height: 24px;
      color: $white;
      min-height: 58px;

      &:not(.error):focus {
        outline: none;
        border-color: #ffffff;
      }

      &.error, .error:focus {
        outline: none;
        border: 1px solid $form-input-fail;
      }

      &:disabled {
        opacity: 40%;  
      }

      @include transition(border 0.3s ease-in-out);

      &[type='email'] {
        padding: $form-input-icon-right;
      }

      &[type='number'] {
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        span[aria-valuenow] {
          color: $white;
        }
      }

      &[type='date'] {
        -webkit-appearance: none;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        span[aria-valuenow] {
          color: $white;
        }

        &:before {
          color: rgb(117, 117, 117);
          margin-right: 0.5em;
          position: absolute;
        }
        &:focus:before,
        &:valid:before {
          content: '';
        }
      }

      &:active,
      &:focus {
        &::-webkit-datetime-edit-fields-wrapper {
          color: $grey-caption;
        }
      }
      &::-webkit-datetime-edit-fields-wrapper {
        font-weight: normal;
        font-size: $form-input-size;
        color: rgb(117, 117, 117);
        background: inherit;
        text-transform: uppercase;
      }

      &::-webkit-calendar-picker-indicator {
        display: none;
      }

      &::placeholder {
        color: $grey-legal-border;
      }
    }

    .label-txt {
      font-size: $form-label-size;
      color: $white;
      position: relative;

      .input-txt {
        @include sofiaRegular();
      }
    }

    .field-label {
      text-transform: uppercase;
      font-size: 12px;
      text-align: left;
      color: #fff;
      position: relative;
      font-family: $sofiapro;
      font-weight: bold;
      letter-spacing: 1.5px;
      display: inherit;
      line-height: 1.33;
    }

    .error {
      border: 1px $form-input-fail solid;
    }
    .input-svg {
      position: absolute;
      top: 10px;
      right: 10px;

      &.disabled {
        opacity: 0.4;
        cursor: default !important;
      }
    }
    .error-text {
      text-align: left;
      color: $form-input-fail;
      font-size: $form-text-large;
      margin-top: -2px;
      display: block;
      @include sofiaRegular();
      line-height: 16px;
    }
    @media (hover: none) and (pointer: coarse) {
      input[type='date']:not(:focus):not(.filled):before {
        content: attr(placeholder);
        pointer-events: none;
      }
    }
  }
}
